<template>
  <div class="info_wrap">
    <div class="info_con" v-if="!successBool">
      <div class="info_title">
        {{serverType ===0  && editType == 1 ? $t(`personalCenter.account.contactAddBtn`)+$t(`personalCenter.account.contactTitle`) : serverType ===0  && editType == 2 ? $t(`personalCenter.account.contactEditBtn`)+$t(`personalCenter.account.contactTitle`) : $t(`personalCenter.account.writeTitle`)+$t(`personalCenter.account.contactTitle`)}}
      </div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-position="top"
        class="info_form"
        @submit="submitForm('ruleForm')"
      >
        <el-form-item
          prop="contactName"
          :label="$t(`personalCenter.account.form.userName.label`)"
        >
          <el-input
            v-model="ruleForm.contactName"
            auto-complete="off"
            :placeholder="$t(`personalCenter.account.form.userName.placeholder`)"
            @keyup.enter.native="submitForm('ruleForm')"
          />
        </el-form-item>

        <el-form-item
          prop="companyName"
          :label="$t(`personalCenter.account.form.companyName.label`)"
        >
          <el-input
            v-model="ruleForm.companyName"
            auto-complete="off"
            :placeholder="$t(`personalCenter.account.form.companyName.placeholder`)"
            @keyup.enter.native="submitForm('ruleForm')"
          />
        </el-form-item>

        <el-form-item
          prop="contactEmail"
          :label="$t(`personalCenter.account.form.email.label`)"
        >
          <el-input
            v-model="ruleForm.contactEmail"
            auto-complete="off"
            :placeholder="$t(`personalCenter.account.form.email.placeholder`)"
            @keyup.enter.native="submitForm('ruleForm')"
          />
        </el-form-item>

        <el-form-item prop="contactPhone" :label="$t(`personalCenter.account.form.phone.label`)">
          <el-input
            v-model="ruleForm.contactPhone" auto-complete="off" maxlength="11"
            :placeholder="$t(`personalCenter.account.form.phone.placeholder`)"
            @keyup.enter.native="submitForm('ruleForm')"
          />
        </el-form-item>

        <el-form-item label="监控邮箱" prop="monitorEmail">
          <el-input
            v-model="ruleForm.monitorEmail"
            auto-complete="off"
            placeholder="请填写商标监控发送的邮箱地址(最多五个,大于五个取前五个)"
            @keyup.enter.native="submitForm('ruleForm')"
          />
        </el-form-item>

        <el-form-item>
          <el-button class="btn" @click="submitForm('ruleForm')" :loading="loading>0" :disabled="disabledBtn">
            {{serverType === 0 ? $t("personalCenter.account.form.saveBtn") :
            $t("personalCenter.account.form.submitBtn")}}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="success" v-show="serverType !=0  && successBool">
      <p>
        <i>
          <img src="@/assets/images/common/success_icon.png" alt="">
        </i>
        {{$t("personalCenter.account.success")}}
      </p>

      <a class="btn" href="/personalCenter/myServer">
        {{$t("personalCenter.account.successBtn")}}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "info",
  props: ['serverType', 'dialogClose', 'dialogVisible'],
  components: {
  },
  data () {
    // 表单验证规则
    // 校验手机号码
    var phoneReg = /^[1][0-9]{10}$/

    var emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    var validatePhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t(`personalCenter.account.form.phone.errorMessage`)))
      }
      setTimeout(() => {
        if (!phoneReg.test(value)) {
          callback(new Error(this.$t(`personalCenter.account.form.phone.errorMessage`)))
        }
        else {
          callback()
        }
      }, 1000)
    }

    // 校验邮箱
    var validateReg = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t(`personalCenter.account.form.email.errorMessage`)))
      }
      setTimeout(() => {
        if (!emailReg.test(value)) {
          callback(new Error(this.$t(`personalCenter.account.form.email.errorMessage`)))
        }
        else {
          callback()
        }
      }, 1000)
    }

    return {
      editType: 1,
      // 表单参数
      ruleForm: {
        contactName: "",
        companyName: "",
        contactEmail: "",
        contactPhone: "",
        monitorEmail:"",
      },
      // 表单验证
      rules: {
        contactName: [
          {required: true, message: this.$t(`personalCenter.account.form.userName.errorMessage`), trigger: 'blur'},
        ],
        companyName: [
          { required: true, message: this.$t(`personalCenter.account.form.companyName.errorMessage`), trigger: 'blur' },
        ],
        contactEmail: [
          {
            required: true,
            validator: validateReg,
            trigger: "blur"
          }
        ],
        contactPhone: [
          {required: true, validator: validatePhone, trigger: 'blur'}
        ],
        monitorEmail:[
          {required: true, message:"请至少填写一个监控邮箱", trigger: 'blur'}
        ]
      },
      loading: 0,
      disabledBtn: false,
      successBool: false,
    }
  },
  mounted () {
    this.getContactLanAjax()
    this.getMonitoringEmail()
  },
  methods: {
    //  获取联系人信息
    async getContactLanAjax () {
      let data = await this.$axios.get("/admin/contact/getContact")
      if (data.data.code == 0) {
        if (data.data.data != null) {
          let userData = data.data.data

          this.ruleForm.contactName = userData.contactName
          this.ruleForm.companyName = userData.companyName
          this.ruleForm.contactEmail = userData.contactEmail
          this.ruleForm.contactPhone = userData.contactPhone

          this.editType = 2
        } else {
          this.editType = 1
        }
      }
    },

    // 蓝灯鱼申请服务试用
    async updateStatusAjax () {
      let data = await this.$axios.put('/lantern/serviceLan/updateStatus', {
        serviceName: this.serverType == 3 ? 0 : this.serverType
      });
      if (data.data.msg == 'success') {
        this.successBool = true;
      }
    },

    //获取监控邮箱
    async getMonitoringEmail() {
      let data = await this.$axios.get("/trademark/monitoring/email/list")
      if (data != null){
        let s = ""
        let userMonitorEmailList = data.data.data
        if (userMonitorEmailList.length > 0){
          userMonitorEmailList.forEach(item=>{
            s += item.userEmail
            s += "；"
          })
          this.ruleForm.monitorEmail = s.substring(0, s.length-1)
        }
      }
    },

    // 表单提交
    submitForm (formName) {
      //监控邮箱修改成功才可进行之后的账号信息编辑程序
      let emailMessage = this.ruleForm.monitorEmail
        .replaceAll(' ', ";")
        .replaceAll(',',";")
        .replaceAll('，',";")
        .replaceAll('；', ";")

      let emailList = emailMessage.split(";")
      let email5 = []

      let emailRed = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

      emailList.map(item=>{
        //此处后续可加邮箱格式验证
        if (item != '' && email5.length < 5 && emailRed.test(item)){
          email5.push(item)
        }
      })

      if (email5.length < 1){
        this.$message("请检查您的邮箱地址格式")
        return
      }

      //上传监控邮箱
      this.$axios.post("/trademark/monitoring/email/batchAddOrUpdate", { userEmailSet : email5})
        .then(response=>{
          if(response.data.data == "邮箱保存成功"){
            this.disabledBtn = true

            setTimeout(() => {
              this.disabledBtn = false
            }, 1000)

            this.$refs[formName].validate(async result => {
              if (result) {
                this.disabledBtn = true
                this.loading++
                // 表单登录接口
                let data,
                  obj = {
                    contactName: this.ruleForm.contactName,
                    companyName: this.ruleForm.companyName,
                    contactEmail: this.ruleForm.contactEmail,
                    contactPhone: this.ruleForm.contactPhone,
                  }
                if (this.editType == 1) {
                  data = await this.$axios.post(
                    '/admin/contact/addContact', obj
                  )
                }
                else if (this.editType == 2) {
                  data = await this.$axios.put(
                    '/admin/contact/updateContact', obj
                  )
                }
                if (data.data.code == 0) {
                  if (this.serverType == 0) {
                    this.$emit("dialog", false)
                  }
                  else {
                    this.updateStatusAjax()
                  }
                }
                this.disabledBtn = false
                this.loading--
              }
            })
          }
          else {
            this.$message("监控邮箱修改失败")
            return
          }
        })
        .catch(error=>{
          this.$message("监控邮箱更新失败")
        })
    },
  },
  watch: {
    dialogClose (param) {
      if (param) {
        this.$refs['ruleForm'].resetFields();
        this.$emit("dialogCloseBool", false)
      }
    },
    dialogVisible (param) {
      if (param) {
        this.getContactLanAjax()
        this.getMonitoringEmail()
      }
    }
  }
}
</script>

<style lang='less' scoped>
@blue: #2f56de;
.info_wrap {
  width: 600px;
  height: 740px;
  background-color: #ffffff;
  box-shadow: 0px 6px 48px 0px rgba(128, 128, 128, 0.16);
  border-radius: 5px;
  .info_title {
    font-size: 18px;
    color: #333333;
    position: relative;
    margin-bottom: 30px;
    &:before {
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 200px;
      position: absolute;
      left: -21px;
      top: 50%;
      margin-top: -3px;
      border: solid 1px @blue;
    }
  }
  .btn {
    width: 100%;
    height: 45px;
    background-color: @blue;
    border-radius: 5px;
    color: #fff;
    font-size: 16px;
    border: none;
    margin-top: 28px;
    &:hover {
      box-shadow: @boxShadow;
    }
  }
  .success {
    padding: 0 55px;
    p {
      text-align: center;
      font-size: 20px;
      color: #595a5e;
      line-height: 254px;
      i {
        display: inline-block;
        width: 36px;
        height: 36px;
        margin-right: 7px;
        vertical-align: middle;
        img {
          width: 100%;
        }
      }
    }
    .btn {
      display: block;
      width: 100%;
      height: 45px;
      line-height: 45px;
      text-align: center;
      border-radius: 5px;
      background: @blue;
      color: #fff;
      margin: 0 auto;
      font-size: 16px;
    }
  }
}
</style>

<style lang="less">
@blue: #2f56de;
.info_wrap {
  .el-input__inner {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #dbdcdd;
    font-size: 14px;
    color: #888;
    padding-left: 0;
  }
  .el-form--label-top .el-form-item__label {
    padding: 0;
    position: relative;
  }
  .el-form-item.is-required:not(.is-no-asterisk)
    .el-form-item__label-wrap
    > .el-form-item__label:before,
  .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
    position: absolute;
    right: -14px;
    color: @blue;
  }
  .el-select {
    width: 100%;
  }
  .el-select .el-input.is-focus .el-input__inner {
    border-color: #dbdcdd;
  }
  .el-select .el-input__inner:focus {
    border-color: #dbdcdd;
  }
}
</style>
